import React from 'react';
import Head from 'next/head';

import { FRONTEND_DOMAIN } from '../constants/api';

function OGMetaTags({ title, desc, image, keywords, category, robots, canonical }) {
  // Setting defaults
  const defaults = {
    title: 'StudyIQ IAS: Best Online Courses for UPSC IAS and State PCS Exams',
    // eslint-disable-next-line max-len
    desc: 'StudyIQ IAS provides best online smart courses for UPSC IAS, State PCS and Judiciary exams, with smart features like live classes, doubts management, videos, online test, magazines, and notes in Hindi and English.',
    image: `${FRONTEND_DOMAIN}/og-image.jpg`,
  };

  return (
    <Head>
      <title>{title?.length ? title : defaults.title}</title>

      <meta name="description" content={desc?.length ? desc : defaults.desc} />

      {keywords?.length && <meta name="keywords" content={keywords} />}
      {category?.length && <meta name="category" content={category} />}

      <meta name="robots" content={robots?.length ? robots : 'index, follow'} />

      {canonical?.length && (
        <>
          <link rel="canonical" href={canonical} />
          <meta property="og:url" content={canonical} />
        </>
      )}

      {/* Facebook OG Meta Tags */}
      <meta property="og:title" content={title?.length ? title : defaults.title} />
      <meta property="og:description" content={desc?.length ? desc : defaults.desc} />
      <meta property="og:image" content={image?.length ? image : defaults.image} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Study IQ Education" />
      <meta property="og:url" content="https://www.studyiq.com/" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title?.length ? title : defaults.title} />
      <meta name="twitter:description" content={desc?.length ? desc : defaults.desc} />
      <meta name="twitter:image" content={image?.length ? image : defaults.image} />
      <meta name="twitter:image:alt" content="Study IQ" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@studyiq" />
    </Head>
  );
}

export default React.memo(OGMetaTags);
